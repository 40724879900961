import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ['form', 'loadingSpinner', 'isNewImages']
  static outlets = ['justified-gallery']

  initialize() {
    // set up intersection observer which runs appear()
    useIntersection(this, {
      element: this.loadingSpinnerTarget
    })
  }
  isNewImagesTargetConnected() {
    const isNewImages = this.isNewImagesTarget.dataset.isNewImages

    // only run this if justified gallery exists and there are new images to digest
    if (this.hasJustifiedGalleryOutlet && isNewImages === 'true') {
      this.updateJustifiedGallery()
    }
  }
  appear() {
    // callback automatically triggered when the element
    // intersects with the viewport
    this.submitForm()
  }

  submitForm() {
    // submit form to fetch new images
    // has to be requestSubmit() for Turbo to intercept
    this.formTarget.requestSubmit()
  }

  requestMoreImages() {
    if (this.hasLoadingSpinnerTarget) {
      this.submitForm()
    }
  }

  updateJustifiedGallery() {
    this.justifiedGalleryOutlet.updateGallery()
  }
}
