import ModalHelperController from '../shared/modals/modal_helper'

import '@glidejs/glide/dist/css/glide.core.css'
import Glide from '@glidejs/glide'

// Connects to data-controller="fullscreen-slider"
export default class extends ModalHelperController {
  static targets = ['image', 'isMoreImages']
  static outlets = ['infinite-scroll']

  static values = {
    currentIndex: Number,
    loading: Boolean,
    loadingText: String
  }

  connect() {
    this.glideInstance = null
    this.waitingForServerResponse = false
    this.loadingValue = true
  }

  disconnect() {
    // close if navigating to another page (via Turbo navigation)
    this.close()
  }

  imageTargetConnected() {
    if (this.waitingForServerResponse) {
      this.loadImagesAndInitializeGlide(this.currentIndexValue, true)
      this.waitingForServerResponse = false
    }
  }

  currentIndexValueChanged() {
    if (this.isMoreImages && this.currentIndexValue === this.totalIndex) {
      // request more images
      // once they're updated on the page imageTargetConnected() will fire
      this.loadingValue = true
      this.loadingTextValue = 'Fetching more images'
      this.infiniteScrollOutlet.requestMoreImages()
      this.waitingForServerResponse = true
    }
  }

  async loadImagesAndInitializeGlide(startAtIndex = 0, reload = false) {
    // filter images that don't yet have src set
    const imagesToLoad = this.imageTargets.filter((img) => img.getAttribute('src').length === 0)
    const imagesToLoadCount = imagesToLoad.length

    this.loadingTextValue = reload ? `Loading ${imagesToLoadCount} more images...` : `Loading ${imagesToLoadCount} images...`

    // fullscreen images by default don't have their src set (optimization)
    // the img url is stored in data-pic-url
    // copy the url to src and wait for the images to be requested
    const imageLoads = imagesToLoad.map((img) => {
      return new Promise((resolve) => {
        const url = img.dataset.picUrl
        img.onload = resolve
        img.onerror = resolve // Resolve on error to not block Glide initialization
        img.src = url
      })
    })

    // Wait for all images to load
    await Promise.all(imageLoads)

    if (reload) {
      this.glideInstance.destroy()
    }

    // Once all images have loaded, initialize Glide
    this.initializeGlide(startAtIndex)
  }

  initializeGlide(startAtIndex = 0) {
    this.glideInstance = new Glide('.glide', {
      type: 'slider',
      autoplay: false,
      rewind: false,
      startAt: startAtIndex
    }).mount()

    this.glideInstance.on('move.after', () => {
      this.currentIndexValue = this.glideInstance.index
    })

    this.loadingValue = false
    this.loadingTextValue = ''
    this.currentIndexValue = this.glideInstance.index
  }

  async open(picId) {
    // Destroy any previous instance
    if (this.glideInstance) {
      this.glideInstance.destroy()
    }

    this.element.dataset.enabled = 'true'
    this.hideScrollbar()
    this.element.focus() // required for esc button to work
    const startAtIndex = this.imageTargets.findIndex((slide) => slide.dataset.picId === picId.toString())
    await this.loadImagesAndInitializeGlide(startAtIndex)
  }

  close() {
    this.element.dataset.enabled = 'false'
    this.showScrollbar()
  }

  get totalIndex() {
    return this.imageTargets.length - 1
  }

  get isMoreImages() {
    return this.isMoreImagesTarget.dataset.isMoreImages === 'true'
  }
}
