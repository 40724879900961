import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import justifiedGallery from 'justifiedGallery'
import 'justifiedGallery/dist/css/justifiedGallery.css'
justifiedGallery($)

export default class extends Controller {
  static values = {
    rowHeight: Number,
    margin: Number
  }
  initialize() {
    this.initializeGallery()
  }

  initializeGallery() {
    // Justified Gallery and Jquery are included in the head of the page

    // eslint-disable-next-line no-undef
    $(this.element)
      .justifiedGallery({
        rowHeight: parseInt(this.rowHeightValue),
        margins: parseInt(this.marginValue),
        border: 0,
        captions: false,
        lastRow: 'nojustify',
        waitThumbnailsLoad: false,
        cssAnimation: false,
        imagesAnimationDuration: 0
      })
      .on('jg.complete', () => {
        this.showGallery()
      })
  }

  showGallery() {
    this.dispatch('showGallery')
  }
  updateGallery() {
    // Analyze new images added to the page
    // https://miromannino.github.io/Justified-Gallery/endless-scroll

    // eslint-disable-next-line no-undef
    $(this.element).justifiedGallery('norewind')
  }
}
