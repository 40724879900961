import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="image-grid"
export default class extends Controller {
  static values = {
    type: String
  }

  static outlets = ['fullscreen-slider']

  initialize() {
    // Run show for all image_grid types apart from rows
    // rows will be shown after justified_gallery_controller event "showGallery" is dispatched
    // action: justified-gallery:showGallery->image-grid#show
    if (this.typeValue !== 'rows') {
      setTimeout(() => {
        this.show()
      }, 200)
    }
  }

  show() {
    this.element.style.opacity = 1
  }

  expandThumbnail(event) {
    event.preventDefault()
    this.fullscreenSliderOutlet.open(event.params.thumbnailPicId)
  }
}
