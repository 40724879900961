import { application } from '../application'

// IA
import Shared_CookiesBannerController from '../shared/cookies_banner_controller'
application.register('cookies-banner', Shared_CookiesBannerController)

// Modals
import Shared_Modals_BasicModalController from '../shared/modals/basic_modal_controller'
application.register('basic-modal', Shared_Modals_BasicModalController)

import StoresV2_Modals_BasketModalController from './modals/basket_modal'
application.register('basket-modal', StoresV2_Modals_BasketModalController)

// Splash
import StoresV2_SplashController from './splash'
application.register('splash', StoresV2_SplashController)

// Header
import StoresV2_HeaderController from './header_controller'
application.register('header', StoresV2_HeaderController)

// Menu
import StoresV2_MenuController from './menu_controller'
application.register('menu', StoresV2_MenuController)

import StoresV2_SearchController from './search_controller'
application.register('search', StoresV2_SearchController)

// Pics / Image grid
import StoresV2_InfiniteScrollController from './infinite_scroll_controller'
application.register('infinite-scroll', StoresV2_InfiniteScrollController)

import StoresV2_ImageGridController from './image_grid_controller'
application.register('image-grid', StoresV2_ImageGridController)

import StoresV2_JustifiedGalleryController from './justified_gallery_controller'
application.register('justified-gallery', StoresV2_JustifiedGalleryController)

import StoresV2_FullscreenSliderController from './fullscreen_slider_controller'
application.register('fullscreen-slider', StoresV2_FullscreenSliderController)

import StoresV2_Pics_ShowController from './pics/show_controller'
application.register('pics-show', StoresV2_Pics_ShowController)
